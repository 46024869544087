import { render, staticRenderFns } from "./userDefined.vue?vue&type=template&id=3b38ab89&"
import script from "./userDefined.vue?vue&type=script&lang=js&"
export * from "./userDefined.vue?vue&type=script&lang=js&"
import style0 from "./userDefined.vue?vue&type=style&index=0&style=%20background-color%3A%20red%3B&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports